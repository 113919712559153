<template>
  <div class="app-container">
    <div class="c_top">
      <p class="c_t_title">
        欢迎使用
        <span>ColorX色库</span
        ><!--，请输入产品号，即可查询！-->
      </p>
      <div class="c_t_radiocontent">
        <el-radio-group v-model="inputType" @change="inputTypeChange">
          <el-radio-button label="0">设备查色</el-radio-button>
          <el-radio-button label="1">色号查色</el-radio-button>
          <el-radio-button label="2">色板查色</el-radio-button>
        </el-radio-group>
      </div>
      <div class="c_t_inputcontent">
        <el-select
          v-model="colorNoType"
          placeholder="请选择色号类型"
          @change="colorNoTypeChange"
          style="width: 150px"
          v-if="inputType == '1'"
        >
          <el-option label="RAL" value="2"></el-option>
          <el-option label="PANTONE" value="1"></el-option>
        </el-select>
        <el-select
          v-model="deviceType"
          placeholder="请选择设备品牌"
          @change="deviceTypeChange"
          style="width: 150px"
          v-if="inputType == '0'"
        >
          <el-option label="爱色丽" value="1"></el-option>
          <el-option label="美能达" value="2"></el-option>
        </el-select>
        <div class="c_t_i_left">
          <div class="c_t_i_l_top">
            <el-input
              v-if="inputType == '1'"
              style="width: 300px"
              v-model="ralColorNo"
              placeholder="请输入色号查询,例如:1001"
              maxlength="100"
            ></el-input>
            <el-input
              placeholder="请输入L值"
              v-model="Lvaule"
              style="width: 50px"
              v-if="inputType == '0' || inputType == '2'"
            >
              <template slot="prepend">L值</template>
            </el-input>
            <el-input
              placeholder="请输入A值"
              v-model="Avaule"
              style="width: 50px"
              v-if="inputType == '0' || inputType == '2'"
            >
              <template slot="prepend">A值</template>
            </el-input>
            <el-input
              placeholder="请输入B值"
              v-model="Bvaule"
              style="width: 50px"
              v-if="inputType == '0' || inputType == '2'"
            >
              <template slot="prepend">B值</template>
            </el-input>
            <el-color-picker
              class="color"
              style="margin-top: 3px"
              @active-change="getcolor"
              v-model="color1"
              v-if="inputType == '2'"
            ></el-color-picker>
          </div>
        </div>
        <el-button class="btn" type="primary" @click="getDataList"
          >查询</el-button
        >
        <el-button
          class="btn"
          type="primary"
          @click="getSetmessage"
          :disabled="isdisabled"
          :loading="loading"
          v-if="inputType == '0' && deviceType == '1'"
          >读取设备</el-button
        >
      </div>
      <div
        class="c_t_colorcontent"
        :style="{ background: color1 }"
        v-if="inputType == '2'"
      >
        <p>{{ color1 }}</p>
        <el-color-picker
          class="color"
          @active-change="getcolor"
          v-model="color1"
        ></el-color-picker>
        <!-- <img src="@/assets/yqt1.png" /> -->
      </div>
    </div>
    <div class="c_main">
      <el-form
        :inline="true"
        size="small"
        :model="dataForm"
        ref="dataForm"
        @keyup.enter.native="restPage()"
        style="padding: 0 15px"
      >
        <!--品牌选择标签-->
        <el-form-item>
          产品品牌
          <el-select
            v-model="dataForm.brandId"
            size="small"
            placeholder="请选择品牌"
            style="width: 60%"
            @change="MLGB1(), restPage()"
            :disabled="factoryBrandState"
          >
            <el-option
              v-for="item in brandList"
              :key="item.id"
              :label="item.brandName"
              :value="item.id"
              size="small"
            ></el-option>
          </el-select>
        </el-form-item>
        <!--工厂选择标签/跟产品品牌做级联-->
        <el-form-item>
          产品工厂
          <el-select
            v-model="dataForm.factoryId"
            size="small"
            placeholder="请选择工厂"
            style="width: 60%"
            @change="restPage()"
          >
            <el-option
              v-for="item in factoryList"
              :key="item.id"
              :label="item.factoryName"
              :value="item.id"
              size="small"
            ></el-option>
          </el-select>
        </el-form-item>
        <!--产品表面选择标签-->
        <el-form-item>
          产品表面
          <el-select
            v-model="dataForm.surface"
            size="small"
            placeholder="请选择表面"
            style="width: 60%"
            @change="restPage()"
          >
            <el-option
              v-for="item in productSurfaceList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
              size="small"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          产品编号
          <el-input
            v-model="dataForm.productNo"
            placeholder="产品号"
            size="small"
            style="width: 63%"
            @change="restPage()"
          ></el-input>
        </el-form-item>
        <el-form-item>
          色差值
          <el-input
            v-model="dataForm.e00Value"
            placeholder="色差值"
            size="small"
            style="width: 60%"
            @change="restPage()"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item prop="ralColorNo">
        RAL色号
        <el-input
          v-model="dataForm.ralColorNo"
          placeholder="RAL色号"
          size="small"
          clearable
          style="width: 60%"
        ></el-input>
        </el-form-item>-->
        <el-form-item>
          产品分类
          <el-select
            v-model="dataForm.productCategory"
            size="small"
            placeholder="请选择分类"
            style="width: 60%"
            @change="restPage()"
          >
            <el-option
              v-for="item in ProductCategoryList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
              size="small"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          产品状态
          <el-select
            v-model="dataForm.state"
            size="small"
            placeholder="请选择产品状态"
            style="width: 60%"
            @change="restPage()"
          >
            <el-option
              v-for="item in testList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              size="small"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          产品光泽
          <el-select
            v-model="dataForm.gloss"
            size="small"
            placeholder="请选择光泽"
            style="width: 34%"
            @change="MLGB(), restPage()"
          >
            <el-option
              v-for="item in productGlossList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
              size="small"
            >
              <span style="float: left">{{ item.name }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{
                item.value
              }}</span>
            </el-option>
          </el-select>
          <el-input
            style="width: 20%"
            v-model="dataForm.glossValue"
            placeholder="光泽值"
            size="small"
            @change="restPage()"
            :disabled="glossState"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="reset('dataForm')" size="small">清空</el-button>
        </el-form-item>
      </el-form>
      <div class="c_m_cards" v-loading="dataListLoading">
        <!-- <div class="c_m_card"> -->
        <el-card
          v-if="dataList.length > 0"
          :body-style="{ padding: '0px' }"
          v-for="(item, index) in dataList"
          :key="index"
        >
          <img
            @click="cpdetailsHandle(item.id, item.e00Value, item.e00lab2)"
            :src="item.picUrl"
            class="c_m_c_img"
            v-if="item.picUrl !== ''"
          />
          <div class="c_m_c_bot">
            <span class="c_m_c_b_tit">{{
              item.brandName == null ? 'TIGER' : item.brandName
            }}</span>
            <div class="c_m_c_b_txt">
              <p>
                <span>产品号</span>
                {{ item.productNo == null ? '暂无' : item.productNo }}
              </p>
              <p>
                <span>表面</span>
                {{ item.surface == null ? '暂无' : item.surface }}
              </p>
              <p>
                <span>色差值</span>
                {{ item.e00Value == '-1' ? '0' : item.e00Value }}
              </p>
              <p>
                <span>45°色差值</span>
                {{ item.e00lab2 == '-1' ? '0' : item.e00lab2 }}
              </p>
              <p>
                <span>光泽</span>
                {{ item.gloss == null ? '暂无' : item.gloss }}
              </p>
              <p>
                <span>光泽值</span>
                {{ item.glossValue == null ? '暂无' : item.glossValue }}
              </p>
              <p>
                <span>标准色号</span>
                {{ item.ralColorNo == null ? '暂无' : item.ralColorNo }}
              </p>
              <p>
                <span>分类</span>
                {{
                  item.productCategory == null ? '暂无' : item.productCategory
                }}
              </p>
              <p>
                <span>生产工厂</span>
                {{ item.factoryName == null ? '暂无' : item.factoryName
                }}{{ item.state == '2' ? '' : '实验室' }}
              </p>
            </div>
            <!--<el-button type="primary" size="small" @click="addRecipeHandle(item.id)">查看配方</el-button>-->
            <!-- <el-button type="primary" size="small" @click="SampleHandle(dataForm.id)">生成打样</el-button> -->
          </div>
        </el-card>
        <p
          v-if="dataList.length == 0 && nodata"
          style="margin: 10px auto; font-size: 14px; color: #999"
        >
          暂无数据
        </p>
        <!-- </div> -->
      </div>
      <div id="fenye">
        <el-pagination
          size="small"
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="pageIndex"
          :page-sizes="[18, 36, 54, 108]"
          :page-size="pageSize"
          :total="totalPage"
          prev-text="上一页"
          next-text="下一页"
          layout="prev, pager, next"
        ></el-pagination>
      </div>
    </div>
    <!-- 弹窗, 新增 / 修改 -->
    <!--<add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>-->
    <!-- <add-recipe v-if="addRecipeVisible" ref="addRecipe" @refreshDataList="getDataList"></add-recipe>-->
    <cpdetails v-if="cpdetailsVisible" ref="cpdetails"></cpdetails>
    <!--<sample v-if="sampleVisible" ref="sample"></sample>-->
  </div>
</template>

<script>
import {
  getBrand,
  getFactory,
  syncDevice,
  serachList,
  del,
  getDict,
  selectDevice,
  getLAB,
  gethex,
  searchDeviceData,
  getProductCategory,
  getFactoryBrand
} from '@/api/modules/product'
/*import AddOrUpdate from "./add-or-update";*/
import cpdetails from './cpdetails'
/*import AddRecipe from './recipe'*/
/*import sample from './sample'*/
import { getToken } from '@/utils/auth'
import Cookies from 'js-cookie'
import { glossValue } from '../../utils/validate'
import { getProductAttrValue } from '@/api/modules/productAttributeValue'
export default {
  data() {
    return {
      loading: false,
      device: {},
      deviceList: [],
      timer: null, //首先我在data函数里面进行定义定时器名称：
      timerNum: 5, // 设置定时器时间
      is_query: false,
      dataForm: {
        e00Value: '',
        e00lab2: '',
        productName: '',
        id: '',
        productNo: '',
        picUrl: '',
        qrUrl: '',
        ralColorNo: '',
        gloss: '',
        glossValue: '',
        surface: '',
        colorSeries: '',
        colorSeriesName: '',
        productPerformance: '',
        otherPerformance: '',
        productCategory: '',
        productHierarchy: '',
        industryId: '',
        industryName: '',
        factoryId: '',
        brandId: '',
        description: '',
        labAve: '',
        l1: '',
        a1: '',
        b1: '',
        l2: '',
        a2: '',
        b2: '',
        l3: '',
        a3: '',
        b3: '',
        lAve: '',
        aAve: '',
        bAve: '',
        isOpen: '',
        state: '',
        makeFactory: ''
      },
      brandList: [],
      factoryList: [],
      dataList: [],
      performanceList: [],
      productSurfaceList: [],
      productGlossList: [],
      otherPerformanceList: [],
      ProductCategoryList: [],
      FactoryBrand: [],
      factoryBrandState: false,
      glossState: false,
      sampleVisible: false,
      pageIndex: 1,
      pageSize: 18,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      addRecipeVisible: false,
      a: '',
      s: '',
      productNo: '',
      Lvaule: '',
      Avaule: '',
      Bvaule: '',
      ralColorNo: '',
      color1: '',
      cpdetailsVisible: false,
      isdisabled: false,
      nodata: false,
      inputType: '0',
      deviceType: '1',
      colorNoType: '2',
      options: [
        {
          value: '0',
          label: 'colorx查色'
        },
        {
          value: '2',
          label: '色号查询'
        },
        {
          value: '3',
          label: '多设备查询'
        }
      ],
      testList: [
        {
          value: '0',
          label: '过程样板'
        },
        {
          value: '1',
          label: '实验产品'
        },
        { value: '2', label: '生产产品' }
      ]
    }
  },
  components: {
    /* AddOrUpdate,*/
    cpdetails
    /*sample,*/
    /*AddRecipe*/
  },
  created() {
    let id = Cookies.get('deviceId')
    console.log(id)
    if (id == '') {
      this.isdisabled = true
    }
    // this.getFactoryBrandList()
    // this.getselectDevice()
    // this.getBrandList()
    // this.getFactoryList()
    this.inputType = '0'
    this.color1 = ''
    this.dataList = []
    this.totalPage = 0
    //this.getlabvalue();
    //this.getDataList();

    this.getProductAttrValue()
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
  },
  methods: {
    getProductAttrValue() {
      let params = {}
      getProductAttrValue(params).then(({ data }) => {
        if (data && data.code === 0) {
          for (var i = 0; i < data.list.length; i++) {
            if (data.list[i].productAttrType === '1') {
              //表面
              this.productSurfaceList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '3') {
              //系列
              this.productSeriesList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '6') {
              //主题
              this.productHierarchyList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '2') {
              //色系
              this.colorSeriesList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '7') {
              //性能
              this.performanceList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '4') {
              //应用
              this.industryList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '8') {
              //功能
              this.otherPerformanceList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '5') {
              //分类
              this.ProductCategoryList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '9') {
              //光泽
              this.productGlossList = data.list[i].productAttrValue
            }
          }
        } else {
        }
      })
    },

    //获取颜色
    getcolor(val) {
      //   console.log(val);
      this.color1 = this.colorchange(val)
      this.dataList = []
      this.totalPage = 0
      this.getlabvalue()
    },
    getlabvalue() {
      /*this.productNo="";*/
      this.ralColorNo = ''
      var params = {
        hex: this.color1
      }
      getLAB(params).then(({ data }) => {
        if (data && data.code === 0) {
          console.log(data)
          this.Lvaule = data.lab[0]
          this.Avaule = data.lab[1]
          this.Bvaule = data.lab[2]
          //this.a = data.lab.join(",");
          //this.getDataList();
        }
      })
    },
    gethexvalue() {
      var params = {
        lab: this.a
      }
      gethex(params).then(({ data }) => {
        if (data && data.code === 0) {
          console.log(data)
          this.color1 = data.hex
        }
      })
    },
    //rgb颜色转换hex
    colorchange(color) {
      var regexp = /[0-9]{0,3}/g
      var re = color.match(regexp) //利用正则表达式去掉多余的部分，将rgb中的数字提取
      var hexColor = '#'
      var hex = [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        'A',
        'B',
        'C',
        'D',
        'E',
        'F'
      ]
      for (var i = 0; i < re.length; i++) {
        var r = null,
          c = re[i],
          l = c
        var hexAr = []
        while (c > 16) {
          r = c % 16
          c = (c / 16) >> 0
          hexAr.push(hex[r])
        }
        hexAr.push(hex[c])
        if (l < 16 && l != '') {
          hexAr.push(0)
        }
        hexColor += hexAr.reverse().join('')
      }
      //alert(hexColor)
      return hexColor
      //   // RGB颜色值的正则
      //   var reg = /^(rgb|RGB)/;
      //   if (reg.test(color)) {
      //     var strHex = "#";
      //     // 把RGB的3个数值变成数组
      //     var colorArr = color.replace(/(?:\(|\)|rgb|RGB)*/g, "").split(",");
      //     // 转成16进制
      //     for (var i = 0; i < colorArr.length; i++) {
      //       var hex = Number(colorArr[i]).toString(16);
      //       if (hex === "0") {
      //         hex += hex;
      //       }
      //       strHex += hex;
      //     }
      //     return strHex;
      //   } else {
      //     return String(color);
      //   }
    },
    //获取设备
    getSetmessage() {
      let id = Cookies.get('deviceId')
      console.log(id)
      syncDevice({ deviceId: id }).then(({ data }) => {
        this.loading = true
        if (data && data.code === 0) {
          this.inputType = '0'
          this.timer = setInterval(() => {
            //创建定时器
            if (this.timerNum === 0) {
              // 设置的定时器时间为0后执行的操作
              this.clearTimer() // 关闭定时器
              this.loading = false
              this.timerNum = 5
            } else {
              //查看设备状态
              this.syncDeviceTime(id, this.timerNum)
            }
          }, 1000)
        }
        this.loading = false
      })
    },
    syncDeviceTime(id, timerNum) {
      searchDeviceData({ deviceId: id }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log(data)
          //this.s =  data.LABvalue.split(',');
          //console.log(this.s+"1111111111111111111111111111111111111111111111111111111111111111111111");
          this.Lvaule = data.product.lAve
          this.Avaule = data.product.aAve
          this.Bvaule = data.product.bAve
          this.dataForm.lAve = data.product.lAve
          this.dataForm.aAve = data.product.aAve
          this.dataForm.bAve = data.product.bAve
          this.dataForm.l1 = data.product.l1
          this.dataForm.a1 = data.product.a1
          this.dataForm.b1 = data.product.b1
          this.dataForm.l2 = data.product.l2
          this.dataForm.a2 = data.product.a2
          this.dataForm.b2 = data.product.b2
          this.dataForm.l3 = data.product.l3
          this.dataForm.a3 = data.product.a3
          this.dataForm.b3 = data.product.b3
          this.gethexvalue()
          this.getDataList()
          this.clearTimer() // 关闭定时器
          this.loading = false
        } else {
          this.timerNum = timerNum - 1 // 定时器减1
        }
      })
    },
    clearTimer() {
      //清除定时器
      window.clearInterval(this.timer)
      this.timer = null
      this.timerNum = 5
    },
    //重新查询
    restPage() {
      this.pageIndex = 1
      this.getFactoryBrandList()
      this.getDataList()
      //this.getProductSurfaceList();
      //this.getDictList();
      //this.getProductGlossList();
      this.getBrandList()
      this.getFactoryList()
      this.getProductAttrValue()
      //this.getProductCategoryList();
      /*this.glossState=true;
                console.log('123565467687411654565774561651561'+this.glossState);*/
    },
    MLGB() {
      this.glossState = true
      this.dataForm.glossValue = ''
    },
    MLGB1() {
      this.dataForm.factoryId = ''
    },
    reset(dataForm) {
      this.$refs.dataForm.resetFields()
      this.dataForm.state = ''
      this.dataForm.e00Value = ''
      this.dataForm.glossValue = ''
      this.dataForm.brandId = ''
      this.dataForm.factoryId = ''
      this.dataForm.productPerformance = ''
      this.dataForm.productCategory = ''
      this.dataForm.otherPerformance = ''
      this.dataForm.surface = ''
      this.dataForm.productNo = ''
      this.dataForm.gloss = ''
      this.dataForm.lAve = ''
      this.dataForm.aAve = ''
      this.dataForm.bAve = ''
      this.is_query = false
      this.glossState = false
      this.restPage()
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true
      this.dataList = []
      // let s=''
      // if(this.Lvaule!==''&&this.Avaule!==''&&this.Bvaule!==''){
      //   s=this.Lvaule+','+this.Avaule+','+this.Bvaule
      // }
      // console.log(s)
      var params = {
        state: this.dataForm.state,
        brandId: this.dataForm.brandId,
        factoryId: this.dataForm.factoryId,
        page: this.pageIndex,
        limit: this.pageSize,
        otherPerformance: this.dataForm.otherPerformance,
        surface: this.dataForm.surface,
        gloss: this.dataForm.gloss,
        glossValue: this.dataForm.glossValue,
        lAve: this.Lvaule,
        aAve: this.Avaule,
        bAve: this.Bvaule,
        l1: this.dataForm.l1,
        a1: this.dataForm.a1,
        b1: this.dataForm.b1,
        l2: this.dataForm.l2,
        a2: this.dataForm.a2,
        b2: this.dataForm.b2,
        l3: this.dataForm.l3,
        a3: this.dataForm.a3,
        b3: this.dataForm.b3,
        productCategory: this.dataForm.productCategory,
        // s: s,
        e00Value: this.dataForm.e00Value,
        productNo: this.dataForm.productNo,
        ralColorNo: this.ralColorNo,
        deviceType: this.deviceType,
        colorNoType: this.colorNoType,
        inputType: this.inputType
      }
      if (
        !(this.Lvaule && this.Avaule && this.Bvaule) &&
        !this.ralColorNo &&
        !this.dataForm.productNo
      ) {
        this.dataList = []
        this.totalPage = 0
        this.$message.error('请输入查询参数')
        this.color1 = '#1A2F44'
        this.dataListLoading = false
        return
      }
      if (null != this.s) {
        this.gethexvalue()
      }
      serachList(params).then(({ data }) => {
        if (data && data.code === 0) {
          if (data.page.list.length > 0) {
            this.nodata = false
            this.dataList = data.page.list
            this.totalPage = data.page.totalCount
          } else {
            this.nodata = true
            this.dataList = data.page.list
            this.totalPage = data.page.totalCount
          }
          if (data.page.list.length !== 0) {
            if (data.page.list[0].hex !== null) {
              this.color1 = data.page.list[0].hex
            } else {
              this.color1 = this.color1
            }
          } else {
            this.color1 = this.color1
          }
        } else {
          this.dataList = []
          this.totalPage = 0
          this.$message.error(data.msg)
          if (this.inputType == 2) {
            this.color1 = this.color1
            this.getlabvalue()
          }
          //this.color1 = "#1A2F44";
        }
        this.dataListLoading = false
      })
      if (this.dataForm.lAve && this.dataForm.aAve && this.dataForm.bAve) {
        this.is_query = true
      }
    },
    //获取已授权设备
    getselectDevice() {
      let params = {}
      selectDevice(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.deviceList = data.deviceList
          //   this.device.deviceId = data.deviceList[0].deviceId;
        } else {
          this.deviceList = []
        }
      })
    },
    //将搜索页面中input中的值赋空
    inputNull() {
      /*this.a ="";*/
      this.ralColorNo = ''
      /* this.productNo = "";*/
    },
    //选择设备显示在页面
    handleCommand(command) {
      this.device = command
    },
    //获取其他性能
    getDictList() {
      let params = {
        type: '1'
      }
      getDict(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.otherPerformanceList = data.list
        } else {
          this.otherPerformanceList = []
        }
      })
    },
    //获取品牌列表
    getBrandList() {
      let params = {}
      getBrand(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.brandList = data.list
        } else {
          this.brandList = []
        }
      })
    },
    //根据产品品牌的Id获取工厂列表
    getFactoryList() {
      if (this.dataForm.brandId != null || this.dataForm.brandId != '') {
        let params = {
          brandId: this.dataForm.brandId
        }
        getFactory(params).then(({ data }) => {
          if (data && data.code === 0) {
            this.factoryList = data.list
          } else {
            this.factoryList = []
          }
        })
      } else {
        let params = {
          brandId: ''
        }
        getFactory(params).then(({ data }) => {
          if (data && data.code === 0) {
            this.factoryList = data.list
          } else {
            this.factoryList = []
          }
        })
      }
    },
    //获取产品分类列表
    getProductCategoryList() {
      let params = {}
      getProductCategory(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.ProductCategoryList = data.list
        } else {
          this.ProductCategoryList = []
        }
      })
    },
    //获取产品表面选项
    getProductSurfaceList() {
      let params = {
        type: '2'
      }
      getDict(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.productSurfaceList = data.list
        } else {
          this.productSurfaceList = []
        }
      })
    },
    //获取产品光泽选项
    getProductGlossList() {
      let params = {
        type: '3'
      }
      getDict(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.productGlossList = data.list
          this.productGlossList[data.list.length - 1].value = '95+'
        } else {
          this.productGlossList = []
        }
      })
    },
    //获取当前登录人的工厂信息和品牌信息
    getFactoryBrandList() {
      let params = {}
      getFactoryBrand(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.FactoryBrand = data.factoryBrand
          if (this.FactoryBrand.factoryId) {
            this.factoryBrandState = true
            this.dataForm.brandId = this.FactoryBrand.brandId
            this.getFactoryList()
          }
        } else {
          this.FactoryBrand = []
        }
      })
    },
    //导出excel表格模板
    exportExcelHandle() {
      var params = {}
      var url =
        `${process.env.VUE_APP_BASE_API}/product/product/exportExcel` +
        `?token=${getToken()}`
      // 模拟创建元素实现
      var alink = document.createElement('a')
      alink.href = url
      alink.style = 'display:none'
      alink.click()
    },

    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id)
      })
    },
    // 产品详情
    cpdetailsHandle(id, e00Value, e00lab2) {
      console.log('1111')
      this.cpdetailsVisible = true
      this.$nextTick(() => {
        this.$refs.cpdetails.init(id, e00Value, e00lab2)
      })
    },
    //配方详情
    addRecipeHandle(id) {
      this.addRecipeVisible = true
      this.$nextTick(() => {
        this.$refs.addRecipe.init(id)
      })
    },
    //打样
    SampleHandle(id) {
      this.sampleVisible = true
      this.$nextTick(() => {
        this.$refs.sample.init(id)
      })
    },
    // 删除
    deleteHandle(id) {
      var ids = id
        ? [id]
        : this.dataListSelections.map((item) => {
            return item.id
          })
      this.$confirm(
        `确定对[id=${ids.join(',')}]进行[${id ? '删除' : '批量删除'}]操作?`,
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).then(() => {
        del(ids).then(({ data }) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      })
    },
    //input框change事件
    inputTypeChange(value) {
      this.a = ''
      this.dataForm.l1 = ''
      this.dataForm.a1 = ''
      this.dataForm.b1 = ''
      this.dataForm.l2 = ''
      this.dataForm.a2 = ''
      this.dataForm.b2 = ''
      this.dataForm.l3 = ''
      this.dataForm.a3 = ''
      this.dataForm.b3 = ''
      if (value == '0') {
        this.s = ''
        this.Lvaule = ''
        this.Avaule = ''
        this.Bvaule = ''
        this.ralColorNo = ''
        this.inputType = '0'
        this.deviceType = '1'
        this.dataList = []
        this.totalPage = 0
      } else if (value == '1') {
        this.s = ''
        this.Lvaule = ''
        this.Avaule = ''
        this.Bvaule = ''
        this.ralColorNo = ''
        this.inputType = '1'
        this.colorNoType = '2'
        this.dataList = []
        this.totalPage = 0
      } else if (value == '2') {
        this.ralColorNo = ''
        this.color1 = '#1A2F44'
        this.getlabvalue()
        this.inputType = '2'
        this.dataList = []
        this.totalPage = 0
      }
    },
    deviceTypeChange(value) {
      this.a = ''
      this.dataForm.l1 = ''
      this.dataForm.a1 = ''
      this.dataForm.b1 = ''
      this.dataForm.l2 = ''
      this.dataForm.a2 = ''
      this.dataForm.b2 = ''
      this.dataForm.l3 = ''
      this.dataForm.a3 = ''
      this.dataForm.b3 = ''
      if (value == '1') {
        this.s = ''
        this.ralColorNo = ''
        this.Lvaule = ''
        this.Avaule = ''
        this.Bvaule = ''
        this.deviceType = '1'
        this.dataList = []
        this.totalPage = 0
      } else if (value == '2') {
        this.s = ''
        this.ralColorNo = ''
        this.Lvaule = ''
        this.Avaule = ''
        this.Bvaule = ''
        this.deviceType = '2'
        this.dataList = []
        this.totalPage = 0
      }
    },
    colorNoTypeChange(value) {
      this.a = ''
      this.dataForm.l1 = ''
      this.dataForm.a1 = ''
      this.dataForm.b1 = ''
      this.dataForm.l2 = ''
      this.dataForm.a2 = ''
      this.dataForm.b2 = ''
      this.dataForm.l3 = ''
      this.dataForm.a3 = ''
      this.dataForm.b3 = ''
      if (value == '1') {
        this.ralColorNo = ''
        this.Lvaule = ''
        this.Avaule = ''
        this.Bvaule = ''
        this.colorNoType = '1'
        this.dataList = []
        this.totalPage = 0
      } else if (value == '2') {
        this.ralColorNo = ''
        this.Lvaule = ''
        this.Avaule = ''
        this.Bvaule = ''
        this.colorNoType = '2'
        this.dataList = []
        this.totalPage = 0
      }
    }
  }
}
</script>

<style lang="scss">
#fenye {
  .number,
  .btn-quicknext {
    display: none;
  }
  .active {
    display: block;
  }
  .el-pagination {
    margin-top: 15px;
    text-align: right;
    margin-right: 50px;
  }
}
/* .el-icon-my-export{
      background: url(../../assets/yqt.png) center no-repeat;
      background-size: cover;
  } */
.c_t_i_l_top .el-input__inner {
  border: 0;
  border-radius: 0;
  min-width: 128px;
  height: 37px;
  background: rgba(245, 245, 245, 0.6);
  text-align: center;
}
.c_m_cards .el-card {
  width: 220px;
  /*height: 240px;*/
  margin-left: 19px;
  margin-bottom: 20px;
}
.c_top .el-color-picker__trigger .el-icon-arrow-down:before {
  content: url('../../../src/assets/img/seBan2.png') !important;
  font-size: 18px;
}
.c_top .el-icon-close:before {
  content: url('../../../src/assets/img/seBan2.png') !important;
  font-size: 18px;
}
.c_top .el-color-picker__trigger {
  border: none;
  padding: 0;
  margin: -10px;
  height: 40px;
  width: 38px;
}
.c_top .el-color-picker__color {
  border: none !important;
}
.el-color-dropdown__btns button {
  display: none !important;
}
// .c_top .el-color-dropdown__link-btn {
//   display: none  !important;
// }
// .c_top .el-color-dropdown__btn {
//   display: none  !important;
// }
.el-input-group__prepend {
  background-color: #e4e4e4;
  color: #303133;
  vertical-align: middle;
  display: table-cell;
  position: relative;
  border: 0px solid #dcdfe6;
  border-radius: 0px;
  padding: 0 20px;
  width: 0px;
  white-space: nowrap;
  height: 38px;
}
</style>
<style lang="scss" scoped>
.w100 {
  width: 100px;
}
.mr10 {
  margin-right: 10px;
}
.ml10 {
  margin-left: 10px;
}
.c_top {
  text-align: center;
}
.c_t_title {
  font-size: 28px;
  color: #666666;
  margin-bottom: 42px;
}
.c_t_title span {
  color: #009cff;
}
.c_t_inputcontent {
  display: flex;
  justify-content: center;
}
.c_t_i_l_top {
  display: flex;
  justify-content: center;
  background: rgba(245, 245, 245, 0.6);
  height: 40px;
  border: 1px #e5e5e5 solid;
  align-items: center;
}
.c_t_i_l_top .color {
  width: 23px;
  height: 23px;
}
.c_tips {
  color: #999999;
  font-size: 12px;
}
.btn {
  height: 40px;
  border-radius: 2px;
  margin-left: 10px;
}
.c_t_colorcontent {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 80px;
  align-items: self-start;
  color: #ffffff;
  font-size: 18px;
  padding: 14px;
  margin: 20px 0 20px 0;
}
.c_t_colorcontent p {
  margin: 0;
}
.c_t_colorcontent img {
  width: 28px;
  height: 28px;
}
.c_main {
  border: 1px #f0d1d1 solid;
  padding: 15px 0;
  margin-top: 10px;
}
.c_m_c_img {
  width: 100%;
  height: 160px;
}
.c_m_cards {
  display: flex;
  flex-wrap: wrap;
  min-height: 240px;
}
.c_m_c_bot {
  color: #333333;
  font-size: 15px;
  padding: 0 10px;
}
.c_m_c_b_txt {
  font-size: 12px;
}
.c_m_c_b_txt span {
  color: #999999;
  margin-right: 17px;
}
.c_t_radiocontent {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: center;
  height: 80px;
  -webkit-box-align: self-start;
  -ms-flex-align: self-start;
  align-items: self-start;
  color: #ffffff;
  font-size: 18px;
  padding: 14px;
}
</style>
